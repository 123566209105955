interface ILabelBadgeProps {
  type: 'required' | 'optional';
}
export const LabelBadge = ({ type }: ILabelBadgeProps) => {
  return (
    <>
      {type === 'required' && (
        <span className="u-ml-10 u-text-xxs inline-block rounded bg-page-dangerLabel px-1 py-0.5 font-normal leading-snug text-white">
          必須
        </span>
      )}
      {type === 'optional' && (
        <span className="u-ml-10 u-text-xxs inline-block rounded bg-page-subcolor05 px-1 py-0.5 font-normal leading-snug text-white">
          任意
        </span>
      )}
    </>
  );
};
