'use client';

import Image from 'next/image';
import { useEffect, useState } from 'react';
import Modal from 'react-modal';

import { pageColor } from '@/constants/color';

export interface ICustomModalProps {
  modalIsOpen: boolean;
  modalOnAfterOpenFunc?: () => void;
  modalOnCloseFunc?: () => void;
  type?: 'default' | 'warning' | 'error';
  errorText?: string | React.ReactNode;
  overrideStyle?: object;
  children?: React.ReactNode;
  hasBottomCloseButton?: boolean;
  onModalStatusChange?: (_isOpen: boolean) => void;
  closeOnOverlayClick?: boolean;
}

export const CustomModal = ({
  modalIsOpen,
  modalOnAfterOpenFunc = () => {},
  modalOnCloseFunc = () => {},
  type = 'default',
  errorText,
  overrideStyle,
  children,
  hasBottomCloseButton = true,
  onModalStatusChange,
  closeOnOverlayClick = true,
}: ICustomModalProps) => {
  const [modalStatusIsOpen, setModalStatusIsOpen] = useState(modalIsOpen);
  useEffect(() => {
    setModalStatusIsOpen(modalIsOpen);
  }, [modalIsOpen]);
  useEffect(() => {
    if (onModalStatusChange) {
      onModalStatusChange(modalStatusIsOpen);
    }
  }, [modalStatusIsOpen, onModalStatusChange]);

  const defaultStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: 'calc(100% - 32px)',
      height: 'fit-content',
      border: 'none',
      borderRadius: '8px',
      padding: '0',
    },
    overlay: {
      backgroundColor: 'rgba(51,51,51, 0.5)',
      zIndex: 80,
    },
  };

  const warningStyles = type === 'warning' && {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: 'calc(100% - 32px)',
      height: 'fit-content',
      border: `3px solid ${pageColor.subcolor17}`,
      borderRadius: '8px',
      padding: '0',
    },
  };

  const errorStyles = type === 'error' && {
    content: {
      border: `3px solid ${pageColor.subcolor02}`,
      backgroundColor: pageColor.bg,
      borderRadius: '8px',
      width: '297px',
      margin: 'auto',
      height: 'fit-content',
      padding: '32px 24px 16px',
    },
  };

  const customStyles = {
    ...defaultStyles,
    ...warningStyles,
    ...errorStyles,
    ...overrideStyle,
  };

  const closeModal = () => {
    modalOnCloseFunc();
    setModalStatusIsOpen(false);
  };
  const afterOpenModal = () => {
    modalOnAfterOpenFunc();
  };

  const handleRequestClose = () => {
    if (closeOnOverlayClick) {
      closeModal();
    }
  };

  return (
    <div>
      <Modal
        isOpen={modalStatusIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleRequestClose}
        style={customStyles}
        contentLabel=""
      >
        <div>
          {children}
          {type === 'error' && errorText && (
            <>
              <p className="text-center text-xl font-medium">{errorText}</p>
              <div className="text-center">
                <button
                  type="button"
                  onClick={closeModal}
                  className="mt-4 text-sm underline"
                >
                  閉じる
                </button>
              </div>
            </>
          )}
        </div>
        {type !== 'error' && hasBottomCloseButton && (
          <div className="flex justify-center pb-4">
            <button type="button" onClick={closeModal} className="p-2">
              <span>
                <Image
                  src="/assets/images/icon-close.svg"
                  alt="閉じる"
                  width={50}
                  height={0}
                  style={{ width: '100%', height: 'auto' }}
                />
              </span>
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
};
