import { GraphQLClient } from 'graphql-request';

import apiEndpoint from '@/constants/endpoint';
import { getSdk } from '@/graphql/gql-generated';

// GraphQLクライアントを作成します。

const client = new GraphQLClient(apiEndpoint.graphql, {
  credentials: 'include',
  mode: 'cors',
  },
);

// SDKを取得します。
export const sdk = getSdk(client);

// TODO: authedを作る
