import type { FieldWrapperPassThroughProps } from '../FieldWrapper/FieldWrapper';
import { FieldWrapper } from '../FieldWrapper/FieldWrapper';

type InputFieldProps = FieldWrapperPassThroughProps & {
  type?: 'text' | 'email' | 'password';
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  disabled?: boolean;
  name: string;
  placeholder?: string;
  error?: { message: string };
  value?: string;
  onChange?: (_e: React.ChangeEvent<HTMLInputElement>) => void;
};
export const InputField = ({
  type = 'text',
  label,
  className,
  labelClassName,
  inputClassName,
  helpText,
  name,
  id,
  disabled = false,
  required,
  optional,
  error,
  titleBadgeIsEdge,
  placeholder,
  value,
  onChange,
}: InputFieldProps) => {
  const inputId = id || `input-${name}`;

  return (
    <div className={className}>
      <FieldWrapper
        label={label}
        helpText={helpText}
        required={required}
        optional={optional}
        labelClassName={labelClassName}
        inputClassName={inputClassName}
        titleBadgeIsEdge={titleBadgeIsEdge}
        id={inputId}
      >
        <div>
          <input
            type={type}
            name={name}
            id={inputId}
            className={`block w-full appearance-none rounded border bg-white px-3 py-2 ${error ? 'border-page-danger text-page-danger' : ''} ${disabled ? 'bg-page-formBorder text-page-subText' : 'border-page-formBorder text-page-mainText placeholder:text-page-formBorder'}`}
            disabled={disabled}
            placeholder={placeholder}
            defaultValue={value}
            required={required}
            onChange={onChange}
            aria-invalid={error ? 'true' : 'false'}
            aria-describedby={error ? `${inputId}-error` : undefined}
          />
          {error && (
            <p id={`${name}-error`} className="mt-1 text-sm text-page-danger">
              {error.message}
            </p>
          )}
        </div>
      </FieldWrapper>
    </div>
  );
};
