import { toast, type ToastOptions } from 'react-hot-toast';

export const useToast = () => {
  // テキストのみのToast
  const showNormalToast = (message: string, options?: ToastOptions) => {
    toast(message, options);
  };
  // チェックアイコン付きのToast
  const showSuccessToast = (message: string, options?: ToastOptions) => {
    toast.success(message, options);
  };
  // チェックアイコン付きのToast
  const showErrorToast = (message: string, options?: ToastOptions) => {
    toast.error(message, options);
  };
  return {
    showNormalToast,
    showSuccessToast,
    showErrorToast,
  };
};
