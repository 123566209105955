import type { z, ZodError } from 'zod';

// エラーメッセージのマッピング
export const errors = {
  400: {
    message: '不正なリクエストです。',
    status: 400,
    // その他のエラー詳細
  },
  401: {
    status: 401,
    message: '認証エラーです。',
  },
  500: {
    status: 500,
    message: 'サーバーエラーです。',
  },
};

// エラー型定義
export type Error = {
  message: string;
  status: number;
  fieldErrors?: Record<string, { message: string }>;
};

// 共通のFormState型定義
export type FormState<T> = T & {
  updatedAt: string;
  error: Error | null;
};

export type ActionResultState<T> = {
  data?: T;
  error?: Error | null;
};

// 共通のバリデーション関数
export async function validate<T extends z.ZodTypeAny>(
  data: z.infer<T>,
  schema: T,
): Promise<z.infer<T>> {
  return schema.parse(data);
}

export async function validateFormData<T extends z.ZodTypeAny>(
  formData: FormData,
  schema: T,
): Promise<z.infer<T>> {
  return schema.parse(Object.fromEntries(formData));
}

// 各項目のエラーをマッピング
export async function transformFieldErrors(err: ZodError) {
  return Object.fromEntries(
    err.errors.map(({ path, message }) => [path.join('.'), { message }]),
  );
}

// エラーハンドリング関数
export const handleError = <T>(
  prevState: FormState<T>,
  error: Error,
): FormState<T> => ({
  ...prevState,
  updatedAt: Date.now().toString(),
  error,
});

export type BackendErrorResponse = {
  errors?: Array<{
    message: string;
    locations: Array<{ line: number; column: number }>;
    path: string[];
    extensions?: {
      code: number;
      validation?: {
        [key: string]: unknown;
      };
    };
  }>;
};

const handleValidationErrors = (
  err: NonNullable<BackendErrorResponse['errors']>[number],
): { [key: string]: { message: string } } => {
  const validationErrors: { [key: string]: { message: string } } = {};
  if (err.extensions?.validation) {
    Object.entries(err.extensions.validation).forEach(([key, messages]) => {
      if (Array.isArray(messages) && messages.length > 0) {
        const field = key.replace(/^input./, '');
        validationErrors[field] = { message: messages[0] };
      }
    });
  }
  return validationErrors;
};

const handleNullValueErrors = (
  err: NonNullable<BackendErrorResponse['errors']>[number],
): { [key: string]: { message: string } } => {
  const nullValueErrors: { [key: string]: { message: string } } = {};
  const nullValueErrorMessage = 'Expected value of type "String!", found null.';
  const nonNullableErrorMessage =
    'Expected non-nullable type "String!" not to be null.';

  if (
    err.message.includes(nullValueErrorMessage) ||
    err.message.includes(nonNullableErrorMessage)
  ) {
    if (err.message.includes('input.email')) {
      nullValueErrors.email = { message: 'メールアドレスは必須です' };
    } else if (err.message.includes('input.password')) {
      nullValueErrors.password = { message: 'パスワードは必須です' };
    }
  }
  return nullValueErrors;
};

export const handleBackendErrors = (
  error: BackendErrorResponse,
): { [key: string]: { message: string } } | null => {
  if (!error.errors || error.errors.length === 0) {
    return null;
  }

  const fieldErrors: { [key: string]: { message: string } } = {};

  error.errors.forEach((err) => {
    Object.assign(fieldErrors, handleValidationErrors(err));
    Object.assign(fieldErrors, handleNullValueErrors(err));
  });

  console.log('fieldErrorsfieldErrorsfieldErrors', fieldErrors);
  return Object.keys(fieldErrors).length > 0 ? fieldErrors : null;
};
