import * as React from 'react';

import { LabelBadge } from '../LabelBadge/LabelBadge';

interface IFieldWrapperProps {
  label?: React.ReactNode | string;
  id?: string;
  required?: boolean;
  optional?: boolean;
  labelClassName?: string;
  inputClassName?: string;
  children: React.ReactNode;
  helpText?: string;
  error?: { message: string };
  titleBadgeIsEdge?: boolean; // 任意/必須バッジを右端に表示するか
}

export type FieldWrapperPassThroughProps = Omit<
  IFieldWrapperProps,
  'className' | 'children'
>;

export const FieldWrapper = ({
  label,
  labelClassName,
  inputClassName,
  error,
  helpText,
  children,
  id,
  required,
  optional,
  titleBadgeIsEdge = false,
}: IFieldWrapperProps) => {
  return (
    <div>
      {label && (
        <label
          htmlFor={id}
          className={`inline-block font-medium ${labelClassName} ${titleBadgeIsEdge && 'flex justify-between'}`}
        >
          <span>{label}</span>
          <span className="shrink-0 ">
            {required && <LabelBadge type="required" />}
            {optional && <LabelBadge type="optional" />}
          </span>
        </label>
      )}
      <div className={`mt-2 ${inputClassName}`}>{children}</div>
      {!error && helpText && <div className="mt-1 text-xs">{helpText}</div>}
      {error?.message && (
        <div
          role="alert"
          aria-label={error.message}
          className="mt-1 text-xs text-page-danger"
        >
          {error.message}
        </div>
      )}
    </div>
  );
};
