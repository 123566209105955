'use client';

import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import { z, ZodError } from 'zod';

import { sdk } from '@/clients/graphql-client';
import { Button } from '@/components/Ui/Button/Button';
import { CustomModal } from '@/components/Ui/CustomModal/CustomModal';
import { InputField } from '@/components/Ui/Form/InputField/InputField';
import { Loading } from '@/components/Ui/Loadig/Loading';
import { apiPath } from '@/constants/apiPath';
import apiEndpoint from '@/constants/endpoint';
import { pagePath } from '@/constants/pagePath';
import { useToast } from '@/hooks/useToast';
import {
  type BackendErrorResponse,
  errors,
  type FormState,
  handleBackendErrors,
  handleError,
  transformFieldErrors,
  validateFormData,
} from '@/libs/validation';

const loginInputSchema = z.object({
  email: z
    .string()
    .min(1, 'メールアドレスは必須です')
    .email('有効なメールアドレスを入力してください'),
  password: z
    .string()
    .min(1, 'パスワードは必須です')
    .min(8, 'パスワードは最低8文字以上必要です'),
});

type LoginFormState = FormState<z.infer<typeof loginInputSchema>>;

export const LoginForm = () => {
  const router = useRouter();
  const [formState, setFormState] = useState<LoginFormState>({
    email: '',
    password: '',
    updatedAt: '',
    error: null,
  });

  const [isLoading, setIsLoading] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { showSuccessToast } = useToast();

  const performLogin = async (
    validatedData: z.infer<typeof loginInputSchema>,
  ) => {
    await fetch(apiEndpoint.csrfCookie, {
      method: 'GET',
      credentials: 'include',
      referrer: 'localhost:3001',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    });

    return sdk.Login({
      input: validatedData,
    });
  };

  const handleGenericError = () => {
    setErrorMessage('ログインに失敗しました。もう一度お試しください。');
    setModalIsOpen(true);
  };

  const handleZodError = async (err: ZodError) => {
    const newState = handleError(formState, {
      ...errors[400],
      fieldErrors: await transformFieldErrors(err),
    });
    setFormState(newState);
  };

  const handleBackendError = (response: BackendErrorResponse) => {
    const fieldErrors = handleBackendErrors(response);
    if (fieldErrors) {
      const newState = handleError(formState, {
        ...errors[400],
        fieldErrors,
      });
      setFormState(newState);
    } else {
      handleGenericError();
    }
  };

  const isBackendError = (
    err: unknown,
  ): err is { response: BackendErrorResponse } => {
    return typeof err === 'object' && err !== null && 'response' in err;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);

    try {
      setIsLoading(true);
      const { email, password } = await validateFormData(
        formData,
        loginInputSchema,
      );
      setFormState({
        email,
        password,
        updatedAt: Date.now().toString(),
        error: null,
      });

      await performLogin({ email, password });
      const data = await sdk.LoginCheckMe();
      const registrationStep = data.me.registration_step;

      const response = await fetch(apiPath.redirectApi, {
        method: 'POST',
        body: JSON.stringify({ registrationStep }),
      });
      const { redirectPath } = await response.json();

      showSuccessToast('ログインしました');
      router.push(redirectPath as string);
      // これがないと、キャッシュがクリアされていない時に、遷移されない時がある
      // router.refresh();
    } catch (err: unknown) {
      console.log(err);
      if (err instanceof ZodError) {
        await handleZodError(err);
      } else if (isBackendError(err)) {
        handleBackendError(err.response);
      } else {
        handleGenericError();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      {isLoading && <Loading />}
      <form onSubmit={handleSubmit}>
        <InputField
          label="メールアドレス"
          type="email"
          required
          id="login"
          name="email"
          placeholder="メールアドレスを入力してください"
          error={formState.error?.fieldErrors?.email}
        />
        <InputField
          label="パスワード"
          type="password"
          required
          id="password"
          name="password"
          className="mt-2"
          placeholder="パスワードを入力してください"
          error={formState.error?.fieldErrors?.password}
        />
        <Button buttonType="submit" className="mt-4">
          ログイン
        </Button>

        <div className="mt-4 text-center ">
          <Link
            href={pagePath.passwordResetInputMail}
            className=" text-sm underline"
          >
            パスワードを忘れてしまったら
          </Link>
        </div>
        <div className="mt-12">
          <p className="text-center text-sm">アカウントをお持ちでない場合</p>
          <Link href={pagePath.register}>
            <Button className="mt-2" as="span" styleType="purple">
              会員登録
            </Button>
          </Link>
        </div>
      </form>
      <CustomModal
        modalIsOpen={modalIsOpen}
        modalOnCloseFunc={closeModal}
        type="error"
        errorText={errorMessage}
        overrideStyle={{}}
      />
    </>
  );
};
